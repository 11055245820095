<template>
  <div class="wrap" :style="{ '--color': !!paramData.color ? `#${paramData.color}` : '' }">
    <Navbar :title="$t('page.agentReport')"
      :background="!!paramData.color ? `#${paramData.color}` : ''">
    </Navbar>
    <div class="table_bar">
      <div class="th">
        <i class="iconfont" :class="paramData.icon"></i>
        <span>{{ $t(`agentReport.label.${paramData.label}`) }}</span>
      </div>
      <div class="table_item" v-for="item, index in tableData" :key="`table_${index}`">
        <div class="label">{{ $t(`agentReport.label.${item.label}`) }}</div>
        <div class="value">{{ data[item.value] || 0 }}</div>
      </div>
      <div class="th formula">{{$t('agentReport.formula')}}</div>
    </div>
  </div>
</template>

<script>
import Navbar from '@/components/JNav'
import { Icon } from 'vant';
import { post } from '@/utils/newRequest'
import $format from "@/utils/dateFormat"
export default {
  components: { Navbar, Icon },
  data() {
    this.tableData = [
      { label: 'recharge', value: "pay_money" },
      { label: 'withdraw', value: "extract_money" },
      { label: 'bet', value: "bet_money" },
      { label: 'commission', value: "ref_money" },
      { label: 'admin', value: "admin_money" },
      { label: 'win', value: "win_money" },
      { label: 'promotion', value: "promotion" },
      { label: 'profit', value: "profit" }
    ]
    return {
      paramData: {},
      data: {}
    }
  },
  created() {
    this.paramData = this.$route.query;
    this.getData();
  },
  methods: {
    async getData() {
      try {
        const res = await post("/users/ref/userinfo",
          { act: "personal", start_day: $format(this.paramData.start_day, "yyyy-MM-dd"), end_day: $format(this.paramData.end_day, "yyyy-MM-dd") });
        this.data = res
        console.log(res)
      } catch (error) {
        console.log(error)
        if (error.msg) this.$toast(error.msg);
      }
    }
  }
}
</script>

<style lang="less" scoped>
.table_bar {
  padding: 10px 8px;
  @gap: 10px;

  .td {
    border: 1px solid var(--theme);
    border-radius: 4px;
    margin-bottom: @gap;
    padding: 8px;
  }

  .th {
    .td();
    text-align: center;
    color: var(--color, var(--theme));
    line-height: 18px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;

    >i {
      font-size: 18px;
      margin-right: 8px;
    }

    >span {
      font-size: 14px;
      font-weight: bold;
    }
  }
  .formula{
    font-size: 12px;
    color: #333;
  }

  .table_item {
    display: flex;

    .label {
      .td();
      background: #E6E8F0;
      font-size: 16px;
    }

    .value {
      .td();
      color: #999;
      margin-left: @gap;
      text-align: end;
    }

    >div {
      width: calc((100% - @gap) / 2 - 2px);
    }
  }
}
</style>